import { GoToNextStepRequest } from '~/types/GoToNextStepRequest';
import { RentalApplicationSteps } from '~/types/RentaApplicationSteps';
import { MagicUseCase } from '../magicUseCase';
import { SetCurrentStepUseCase } from './application-steps/setCurrentStepUseCase';

export class GoToPrevStepUseCase extends MagicUseCase {
  protected async runLogic(request: GoToNextStepRequest) {
    switch (request.currentStep) {
      case RentalApplicationSteps.POTENTIAL_UNITS: {
        await new SetCurrentStepUseCase().execute(RentalApplicationSteps.APPLICANT_INFORMATION);
        this.navigate(`/rental-applications/${request.credentials.id}/${request.credentials.password}/applicant-information`);
        break;
      }
      case RentalApplicationSteps.RENTAL_HISTORY: {
        await new SetCurrentStepUseCase().execute(RentalApplicationSteps.POTENTIAL_UNITS);
        this.navigate(`/rental-applications/${request.credentials.id}/${request.credentials.password}/potential-units`);
        break;
      }
      case RentalApplicationSteps.WORK_HISTORY: {
        await new SetCurrentStepUseCase().execute(RentalApplicationSteps.RENTAL_HISTORY);
        this.navigate(`/rental-applications/${request.credentials.id}/${request.credentials.password}/rental-history`);
        break;
      }
      case RentalApplicationSteps.QUESTIONS: {
        await new SetCurrentStepUseCase().execute(RentalApplicationSteps.WORK_HISTORY);
        this.navigate(`/rental-applications/${request.credentials.id}/${request.credentials.password}/employment-income`);
        break;
      }
      case RentalApplicationSteps.TERMS_AND_CONDITIONS: {
        const questions = this.getState().user.rentalApplication.settings?.questions;
        if (questions && questions.length > 0) {
          await new SetCurrentStepUseCase().execute(RentalApplicationSteps.QUESTIONS);
          this.navigate(`/rental-applications/${request.credentials.id}/${request.credentials.password}/rental-questions`);
        } else {
          await new SetCurrentStepUseCase().execute(RentalApplicationSteps.WORK_HISTORY);
          this.navigate(`/rental-applications/${request.credentials.id}/${request.credentials.password}/employment-income`);
        }
        break;
      }
    }
  }
}
